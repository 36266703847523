<template>
  <aside>
    <header>
      <h2>Create a Segment</h2>
      <button class="close" @click="close"><span class="sr-only">Close modal window</span></button>
    </header>

    <main>
      <div class="setting-row">
        <div class="title">
          <h4>Segment Name</h4>
        </div>
        <div class="fields">
          <input type="text" class="form-control" :class="{'is-invalid': $v.name.$error}" v-model="$v.name.$model" />
          <div class="invalid-feedback">Segment name is required.</div>
        </div>
      </div>

      <div class="setting-row segment-type">
          <div class="title">
            <h4>Segment Sync</h4>
          </div>
          <div class="fields">
            <select class="form-control" v-model="sync_type">
              <option value="manually">Choose members manually</option>
              <option value="field_sync">Sync by profile fields</option>
            </select>
          </div>
        </div>

      <div class="setting-row conditions" v-if="sync_type === 'field_sync'">
        <div class="title">
          <h4>Segment Conditions</h4>
        </div>
        <div class="fields">
          <segment-condition-builder v-model="$v.conditions" />
        </div>
      </div>
    </main>

    <footer>
      <button class="btn btn-sm btn-green" :class="{'loading': loading}" @click="addSegment">Create segment</button>
    </footer>
  </aside>
</template>

<script>
import {required, minLength} from 'vuelidate/lib/validators'
import SegmentConditionBuilder from '@/components/Users/SegmentConditionBuilder'

export default {
  components: {
    SegmentConditionBuilder
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    }
  },
  data() {
    return {
      name: '',
      conditions: [],
      sync_type: 'field_sync'
    }
  },
  created() {
    
  },
  watch: {

  },
  validations: {
    name: {
      required
    },
    conditions: {
      minLength: function({length}) {
        if(this.sync_type === 'manually') return true;

        return length > 0;
      },
      $each: {
        $each: {
          field_id: {
            required
          },
          field_value: {
            required
          },
          operator: {
            required
          }
        }
      }
    },
  },
  methods: {
    close() {
      this.$emit('done');
    },
    async addSegment() {
      this.$v.$touch()
      if(this.$v.$invalid) return;

      this.$store.commit('loading', true)

      const resp = await this.$api.Segments.create({
        name: this.name,
        filters: 
          this.conditions.map(group => group.reduce((agg, val) => {
            agg.push({
              field: val.field_id,
              value: val.field_value,
              operator: val.operator
            })
            return agg;
          }, [])
        )
      })

      if(resp.error) {
        this.$store.commit('loading', false)
        this.$toast.error(resp.message)
        return;
      }

      await this.$store.dispatch('populateSegments')

      this.$store.commit('loading', false)

      this.$emit('done', 'CREATED_SEGMENT')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>